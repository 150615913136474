<template>
  <span :class="'dl-compliance-status-' + value">
    {{ label }}
  </span>
</template>

<script>
export default {
  name: "SettlementComplianceStatus",
  props: ["value", "label"],
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
