<template>
  <span :class="'dl-yes-or-no-' + value">
    {{ fmtValue(value) }}
  </span>
</template>

<script>
export default {
  name: "DlYesOrNo",
  props: {
    value: {
      type: String,
      default: 'N',
      required: true,
    },
  },
  methods: {
    fmtValue(val) {
      return val == "Y" ? "是" : "否";
    },
  },
};
</script>