/**
 * 格式化输出货币
 *   number 数值
 *   places 保留的小数位数
 *   symbol 前缀
 *   thousand 千分位分隔符
 *   decimal 小数点位分隔符
 *   suffix 后缀
 **/
export const fmtCurrency = (
  number,
  places,
  symbol,
  thousand,
  decimal,
  suffix
) => {
  number = number || 0;
  //保留的小位数 可以写成 formatMoney(542986,3) 后面的是保留的小位数，否则默 认保留两位
  places = !isNaN((places = Math.abs(places))) ? places : 2;
  //symbol表示前面表示的标志是￥ 可以写成 formatMoney(542986,2,"$")
  symbol = symbol !== undefined ? symbol : "￥";
  //thousand表示每几位用,隔开,是货币标识
  thousand = typeof thousand == "undefined" ? "," : thousand;
  //decimal表示小数点
  decimal = decimal || ".";
  //negative表示如果钱是负数有就显示“-”如果不是负数 就不显示负号
  let negative = number < 0 ? "-" : "";
  //i表示处理过的数值整数部分
  let i = parseInt((number = Math.abs(number || 0).toFixed(places)), 10) + "";
  //后缀
  suffix = suffix || "";
  return (
    symbol +
    negative +
    i.replace(/\d{1,3}(?=(\d{3})+$)/g, "$&" + thousand) +
    (places
      ? decimal +
        Math.abs(number - i)
          .toFixed(places)
          .slice(2)
      : "") +
    suffix
  );
};

/**
 * 格式化输出金额
 * @param number
 * @returns {*}
 * @constructor
 */
export const fmtMoney = (number) => {
  return fmtCurrency(number, 2, "", ",", ".", " 元");
};
