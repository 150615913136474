<template>
  <div class="dl-audit-result">
    <el-row>
      <el-col :span="6" class="dl-audit-result-item">
        <label class="result-t">稽核状态：</label>
        <span>{{
          getDictLabel(dictionary.tradeAuditStatus, settlement.auditStatus)
        }}</span>
      </el-col>
      <el-col :span="6" class="dl-audit-result-item">
        <label class="result-t">稽核时间：</label>
        <span>{{
          settlement.auditLastTime == null
            ? "0000-00-00 00:00:00"
            : settlement.auditLastTime
        }}</span>
      </el-col>
      <el-col :span="6" class="dl-audit-result-item">
        <label class="result-t">发票张数：</label>
        <span>{{ settlement.ticketCount }}</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6" class="dl-audit-result-item">
        <el-tooltip placement="top" effect="light">
          <div slot="content">
            <div>1. 结算订单发票张数；</div>
          </div>
          <label class="result-t pointer">票数稽核：</label>
        </el-tooltip>
        <span v-if="settlement.sheetStage == 'BI'" class="result-z">{{
          getDictLabel(dictionary.tradeAuditStatus, settlement.auditStatus)
        }}</span>
        <template v-else>
          <span class="result-y">已通过</span
          ><span class="result-v">{{ settlement.receivedCount }}</span>
          <span class="result-n">未通过</span
          ><span class="result-v">{{
            settlement.ticketCount - settlement.receivedCount
          }}</span>
        </template>
      </el-col>
      <el-col :span="6" class="dl-audit-result-item">
        <el-tooltip placement="top" effect="light">
          <div slot="content">
            <div>1. 发票查验状态稽核；</div>
            <div>2. 发票验章状态稽核；</div>
          </div>
          <label class="result-t pointer">验真状态：</label>
        </el-tooltip>
        <span v-if="settlement.sheetStage == 'BI'" class="result-z">{{
          getDictLabel(dictionary.tradeAuditStatus, settlement.auditStatus)
        }}</span>
        <template v-else>
          <span class="result-y">已通过</span
          ><span class="result-v">{{ settlement.auditedVpCount }}</span>
          <span class="result-n">未通过</span
          ><span class="result-v">{{
            settlement.receivedCount - settlement.auditedVpCount
          }}</span>
        </template>
      </el-col>
      <el-col :span="6" class="dl-audit-result-item">
        <el-tooltip placement="top" effect="light">
          <div slot="content">
            <div>1. 购方、销方企业名称稽核；</div>
            <div>2. 购方、销方企业税号稽核；</div>
            <div>3. 购方、销方地址电话稽核；</div>
            <div>4. 购方、销方银行账号稽核；</div>
          </div>
          <label class="result-t pointer">抬头信息：</label>
        </el-tooltip>
        <span v-if="settlement.sheetStage == 'BI'" class="result-z">{{
          getDictLabel(dictionary.tradeAuditStatus, settlement.auditStatus)
        }}</span>
        <template v-else>
          <span class="result-y">已通过</span
          ><span class="result-v">{{ settlement.auditedVpCount }}</span>
          <span class="result-n">未通过</span
          ><span class="result-v">{{
            settlement.receivedCount - settlement.auditedVpCount
          }}</span>
        </template>
      </el-col>
      <el-col :span="6" class="dl-audit-result-item">
        <el-tooltip placement="top" effect="light">
          <div slot="content">
            <div>1. 结算订单金额稽核；</div>
            <div>2. 结算订单税额稽核；</div>
          </div>
          <label class="result-t pointer">金额稽核：</label>
        </el-tooltip>
        <span v-if="settlement.sheetStage == 'BI'" class="result-z">{{
          getDictLabel(dictionary.tradeAuditStatus, settlement.auditStatus)
        }}</span>
        <template v-else>
          <span class="result-y">已通过</span
          ><span class="result-v">{{ settlement.receivedCount }}</span>
          <span class="result-n">未通过</span
          ><span class="result-v">{{
            settlement.ticketCount - settlement.receivedCount
          }}</span>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "SettlementAuditResult",
  props: ["settlement"],
  data() {
    return {
      dictionary: {
        tradeAuditStatus: [],
      },
    };
  },
  async created() {
    await this.initDict();
  },
  computed: {},
  methods: {
    // 初始化字典
    async initDict() {
      const { data } = await this.batchGetDicts({
        types: ["trade_audit_status"],
      });
      this.dictionary.tradeAuditStatus = data["trade_audit_status"];
    },
  },
};
</script>

<style lang="scss" scoped>
.dl-audit-result {
  padding: 0px 20px;

  .dl-audit-result-item {
    margin-top: 10px;

    .result-t {
      color: #3c3c3c;
    }

    .pointer {
      color: #1890ff;
      cursor: pointer;
    }

    .result-z {
      color: #ffcc00;
    }

    .result-y::before {
      width: 0px;
      height: 0px;
      content: "";
      margin-right: 5px;
      border-width: 5px;
      border-radius: 3px;
      border-style: solid;
      border-color: green;
      display: inline-block;
    }

    .result-n {
      margin-left: 10px;
    }

    .result-n::before {
      width: 0px;
      height: 0px;
      content: "";
      margin-right: 5px;
      border-width: 5px;
      border-radius: 3px;
      border-style: solid;
      border-color: red;
      display: inline-block;
    }

    .result-v {
      color: #f56c6c;
      margin: 0px 15px 0px 5px;
      display: inline-block;
    }
  }
}
</style>
