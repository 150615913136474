<template>
  <div class="cont-wrap">
    <div class="cont-head">
      <el-row>
        <el-col style="text-align: right">
          <el-button
            type="primary"
            @click="handleQuerySettlement"
            v-hasPermi="['coo:trade:detail']"
            >刷新</el-button
          >
          <el-button
            type="primary"
            @click="handleAuditSettlement"
            :disabled="auditBtnDisabled"
            v-hasPermi="['coo:trade:audit']"
            >重新稽核</el-button
          >
          <el-button
            plain
            type="warning"
            @click="handleUpdateAuditOptions"
            v-hasPermi="['coo:trade:edit-options']"
            v-if="cooRole == 'DS'"
            >修改稽核项目</el-button
          >
          <el-button
            type="default"
            @click="handleRedirectToAuditItem"
            v-hasPermi="['coo:trade:audit-items']"
            >明细稽核</el-button
          >
          <el-button
            type="success"
            @click="handlePrintSettlement"
            :disabled="printBtnDisabled"
            v-hasPermi="['coo:trade:print']"
            >打印凭据</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="cont-body">
      <trade-basic
        scene="JH"
        v-loading="loading"
        :settlement="settlement"
        ref="tradeBasicRef"
      ></trade-basic>
      <el-collapse class="dl-collapse" v-model="activeNames">
        <el-collapse-item title="稽核项目" name="JHXM">
          <audit-option
            :cooRole="cooRole"
            v-loading="loading"
            :settlement="settlement"
            ref="auditOptionRef"
          ></audit-option>
        </el-collapse-item>
        <el-collapse-item title="稽核结果" name="JHJG">
          <audit-result
            :cooRole="cooRole"
            v-loading="loading"
            :settlement="settlement"
            ref="auditResultRef"
          ></audit-result>
        </el-collapse-item>
        <el-collapse-item title="发票清单" name="FPQD">
          <audit-ticket
            :cooRole="cooRole"
            :settlement="settlement"
            ref="auditTicketRef"
          ></audit-ticket>
        </el-collapse-item>
      </el-collapse>
    </div>

    <edit-option
      ref="editOptionRef"
      @success="handleEditOptionSuccess"
    ></edit-option>
  </div>
</template>

<script>
import TradeBasic from "@/views/cooperate/settlement/components/tradeBasic.vue";
import auditOption from "@/views/cooperate/settlement/components/auditOption.vue";
import auditResult from "@/views/cooperate/settlement/components/auditResult.vue";
import auditTicket from "@/views/cooperate/settlement/components/auditTicket.vue";
import EditOption from "@/views/cooperate/settlement/components/editOption.vue";

import {
  reqSelectSettlement,
  reqAuditSettlement,
} from "@/service/coo/trade.js";
import { changeMethods } from "@/util/mixins";

export default {
  name: "CooTradeMatchingDetail",
  components: {
    auditOption,
    auditResult,
    auditTicket,
    TradeBasic,
    EditOption,
  },
  mixins: [changeMethods],
  props: {
    cooRole: {
      type: String,
      default: "SS",
    },
  },
  data() {
    return {
      activeNames: ["JHJG", "FPQD"],
      id: this.$route.query.id,
      settlement: {
        id: this.$route.query.id,
      },
      loading: false,
    };
  },
  computed: {
    auditBtnDisabled() {
      return this.settlement.auditStatus == "CG";
    },
    printBtnDisabled() {
      return "CG" != this.settlement.auditStatus;
    },
  },
  async created() {
    this.$watch(
      () => this.$route.path,
      async () => {
        await this.handleQuerySettlement();
      }
    );

    await this.handleQuerySettlement();
  },
  methods: {
    // 修改稽核项目
    handleUpdateAuditOptions() {
      this.$refs.editOptionRef.showDialog(
        JSON.parse(JSON.stringify(this.settlement))
      );
    },
    // 修改项目成功
    handleEditOptionSuccess() {
      this.queryTrade();
    },
    // 转到稽核明细
    handleRedirectToAuditItem() {
      this.toast("明细稽核功能尚未上线，敬请期待！", "info");
    },
    // 打印结算订单
    handlePrintSettlement() {
      this.$router.replace({
        path: `/coo/${
          this.cooRole == "DS" ? "buy" : "sal"
        }/trade/evidence/detail`,
        query: { id: this.id },
      });
    },
    // 重新稽核订单
    async handleAuditSettlement() {
      const res = await reqAuditSettlement(this.id);
      if (res.success) {
        this.handleQuerySettlement();
      }
    },
    // 查询订单详情
    async queryTrade() {
      this.loading = true;
      const { success, data } = await reqSelectSettlement(this.id);
      if (success) {
        this.settlement = data;
      }
      this.loading = false;
    },
    // 查询订单详情
    async handleQuerySettlement() {
      await this.queryTrade();
      if (this.settlement.id) {
        this.$refs.auditTicketRef.queryInvoice();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cont-head {
  color: #666666;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.cont-body {
  padding: 0px 20px 20px 20px;
  background-color: #ffffff;
}

.dl-collapse {
  ::v-deep .el-collapse-item__header {
    padding: 0px 20px;
  }
}
</style>
