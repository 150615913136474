<template>
  <div>
    <el-table
      stripe
      border
      show-summary
      :data="tableData"
      v-loading="dataLoading"
      :summary-method="getTableSummary"
      highlight-current-row
      :header-cell-style="handleHeaderCellStyle"
    >
      <el-table-column
        type="index"
        width="60"
        label="序号"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="colInvoice.invoiceType"
        label="发票类型"
        min-width="140"
        fixed="left"
        :formatter="fmtInvoiceType"
      />
      <el-table-column
        prop="colInvoice.invoiceNo"
        label="发票号码"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="colInvoice.invoiceCode"
        label="发票代码"
        min-width="140"
      ></el-table-column>
      <el-table-column
        prop="colInvoice.eInvoiceNo"
        label="数电票号"
        min-width="180"
      ></el-table-column>
      <el-table-column
        prop="colInvoice.invoiceTime"
        label="开票日期"
        min-width="120"
        :formatter="fmtInvoiceTime"
      />
      <el-table-column
        prop="colInvoice.receiveTime"
        label="收票时间"
        min-width="180"
        :formatter="fmtReceiveTime"
      />
      <el-table-column
        prop="colInvoice.amount"
        label="发票金额"
        width="120"
        align="right"
        header-align="right"
        :formatter="fmtInvoiceAmount"
      ></el-table-column>
      <el-table-column
        prop="colInvoice.taxAmount"
        label="发票税额"
        width="120"
        align="right"
        header-align="right"
        :formatter="fmtInvoiceAmount"
      ></el-table-column>
      <el-table-column
        prop="colInvoice.sumAmount"
        label="价税合计"
        width="120"
        align="right"
        header-align="right"
        :formatter="fmtInvoiceAmount"
      ></el-table-column>
      <el-table-column
        prop="colRelation.invoicingType"
        label="协同开票"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{
              getDictLabel(
                dictionary.cooperationInvoicingType,
                scope.row.colRelation.invoicingType
              )
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="settlement.auditBuyerName"
        prop="auditBuyerNameIsMatch"
        width="120"
        align="center"
        label="稽核购方名称"
        ><template slot-scope="scope"
          ><audit-value-match
            :value="scope.row.colRelation.auditBuyerNameIsMatch"
          ></audit-value-match></template
      ></el-table-column>
      <el-table-column
        v-if="settlement.auditBuyerTaxNo"
        prop="auditBuyerTaxNoIsMatch"
        width="120"
        align="center"
        label="稽核购方税号"
        ><template slot-scope="scope"
          ><audit-value-match
            :value="scope.row.colRelation.auditBuyerTaxNoIsMatch"
          ></audit-value-match></template
      ></el-table-column>
      <el-table-column
        v-if="settlement.auditBuyerAddressTel"
        prop="auditBuyerAddressTelIsMatch"
        width="120"
        align="center"
        label="稽核购方地址电话"
        ><template slot-scope="scope"
          ><audit-value-match
            :value="scope.row.colRelation.auditBuyerAddressTelIsMatch"
          ></audit-value-match></template
      ></el-table-column>
      <el-table-column
        v-if="settlement.auditBuyerBankAccount"
        prop="auditBuyerBankAccountIsMatch"
        width="120"
        align="center"
        label="稽核购方银行账户"
        ><template slot-scope="scope"
          ><audit-value-match
            :value="scope.row.colRelation.auditBuyerBankAccountIsMatch"
          ></audit-value-match></template
      ></el-table-column>
      <el-table-column
        v-if="settlement.auditSellerName"
        prop="auditSellerNameIsMatch"
        width="120"
        align="center"
        label="稽核销方名称"
        ><template slot-scope="scope"
          ><audit-value-match
            :value="scope.row.colRelation.auditSellerNameIsMatch"
          ></audit-value-match></template
      ></el-table-column>
      <el-table-column
        v-if="settlement.auditSellerTaxNo"
        prop="auditSellerTaxNoIsMatch"
        width="120"
        align="center"
        label="稽核销方税号"
        ><template slot-scope="scope"
          ><audit-value-match
            :value="scope.row.colRelation.auditSellerTaxNoIsMatch"
          ></audit-value-match></template
      ></el-table-column>
      <el-table-column
        v-if="settlement.auditSellerAddressTel"
        prop="auditSellerAddressTelIsMatch"
        width="120"
        align="center"
        label="稽核销方地址电话"
        ><template slot-scope="scope"
          ><audit-value-match
            :value="scope.row.colRelation.auditSellerAddressTelIsMatch"
          ></audit-value-match></template
      ></el-table-column>
      <el-table-column
        v-if="settlement.auditSellerBankAccount"
        prop="auditSellerBankAccountIsMatch"
        width="120"
        align="center"
        label="稽核销方银行账户"
        ><template slot-scope="scope"
          ><audit-value-match
            :value="scope.row.colRelation.auditSellerBankAccountIsMatch"
          ></audit-value-match></template
      ></el-table-column>
      <el-table-column
        width="120"
        align="center"
        label="发票影像"
        v-if="settlement.auditInvoiceTi"
        prop="auditFplFileUpIsMatch"
        ><template slot-scope="scope"
          ><audit-value-match
            :value="scope.row.colRelation.auditFplFileUpIsMatch"
          ></audit-value-match></template
      ></el-table-column>
      <el-table-column
        width="120"
        align="center"
        label="确认收票"
        v-if="settlement.auditInvoiceCr"
        prop="auditInvoiceCrIsMatch"
        ><template slot-scope="scope"
          ><audit-value-match
            :value="scope.row.colRelation.auditInvoiceCrIsMatch"
          ></audit-value-match></template
      ></el-table-column>
      <el-table-column
        width="120"
        align="center"
        label="查验状态"
        v-if="settlement.auditInvoiceVi"
        prop="auditInvoiceViIsMatch"
        ><template slot-scope="scope"
          ><audit-value-match
            :value="scope.row.colRelation.auditInvoiceViIsMatch"
          ></audit-value-match></template
      ></el-table-column>
      <el-table-column
        width="120"
        align="center"
        label="验章状态"
        v-if="settlement.auditInvoiceCs"
        prop="auditInvoiceCsIsMatch"
      >
        <template slot-scope="scope">
          <audit-value-match
            :value="scope.row.colRelation.auditInvoiceCsIsMatch"
          ></audit-value-match>
        </template>
      </el-table-column>
      <el-table-column
        width="120"
        align="center"
        label="进项匹配"
        v-if="settlement.auditInvoiceIm"
        prop="auditInvoiceImIsMatch"
      >
        <template slot-scope="scope">
          <audit-value-match
            :value="scope.row.colRelation.auditInvoiceImIsMatch"
          ></audit-value-match>
        </template>
      </el-table-column>
      <el-table-column
        label="稽核通过"
        width="80"
        header-align="center"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.colRelation.auditPassed" style="color: green"
            >是</span
          >
          <span v-else style="color: red">否</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="operate"
        label="操作"
        fixed="right"
        min-width="80"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            style="padding: 0px"
            @click="handlePreviewImage(scope.row)"
            >影像</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog width="80%" title="发票影像" :visible.sync="invoiceImageVisible">
      <col-invoice-image
        v-if="invoiceImageVisible"
        :col-invoice-id="colInvoiceId"
      ></col-invoice-image>
    </el-dialog>
  </div>
</template>

<script>
import { reqPagingSettlementRelInvoice } from "@/service/coo/trade-rel-invoice.js";
import ColInvoiceImage from "@/views/invoice-center/component/ColInvoiceImage.vue";
import AuditValueMatch from "@/views/cooperate/settlement/widgets/AuditValueMatch.vue";
import { fmtCurrency } from "@/assets/js/format-util";

export default {
  name: "SettlementAuditTicket",
  components: { ColInvoiceImage, AuditValueMatch },
  props: {
    settlement: {
      type: Object,
    },
    cooRole: {
      type: String,
      default: "SS",
    },
  },
  data() {
    return {
      // 数据字典
      dictionary: {
        cooperationInvoicingType: [],
      },
      // 查询参数
      queryParam: {
        oriTradeId: undefined,
        pageNo: 1,
        pageSize: 100,
      },
      // 表格数据
      tableData: [],
      dataLoading: false,

      // 影像浏览
      colInvoiceId: undefined,
      invoiceImageVisible: false,
    };
  },
  async created() {
    await this.initDict();
    this.queryInvoice();
  },
  methods: {
    // 加载数据字典
    async initDict() {
      const { data, success } = await this.batchGetDicts({
        types: ["cooperation_invoicing_type"],
      });
      if (success) {
        this.dictionary.cooperationInvoicingType =
          data["cooperation_invoicing_type"];
      }
    },
    // 转换发票类型
    fmtInvoiceType(row, column, value) {
      if (!value) return "";
      return this.handleValueToLabel("InvoiceType", value);
    },
    // 格式金额字段
    fmtInvoiceAmount(row, column, value) {
      if (!value) return "";
      return fmtCurrency(value, 2, "", "", ".", "");
    },
    // 转换开票日期
    fmtInvoiceTime(row, column, value) {
      if (!value) return "";
      return this.$moment(value).format("YYYY-MM-DD");
    },
    // 转换收票日期
    fmtReceiveTime(row, column, value) {
      if (!value) return "";
      return this.$moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
    // 查询收票列表
    async queryInvoice() {
      this.queryParam.oriTradeId = this.settlement.id;
      if (!this.queryParam.oriTradeId) {
        this.toast("查询发票失败，订单主键不能为空！", "warning");
        return;
      }
      this.dataLoading = true;
      const { data } = await reqPagingSettlementRelInvoice(this.queryParam);
      this.tableData = data.list || [];
      this.dataLoading = false;
    },
    // 计算收票汇总
    getTableSummary(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[index] = "汇总";
        } else {
          let idx = [
            "colInvoice.amount",
            "colInvoice.taxAmount",
            "colInvoice.sumAmount",
          ].indexOf(`${column.property}`);
          if (idx == -1) {
            sums[index] = "";
          } else {
            const values = data.map((item) => {
              if (idx == 0) {
                return Number(item.colInvoice?.amount);
              } else if (idx == 1) {
                return Number(item.colInvoice?.taxAmount);
              } else {
                return Number(item.colInvoice?.sumAmount);
              }
            });

            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + value;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] = Number(sums[index]).toFixed(2) + " 元";
            } else {
              sums[index] = "";
            }
          }
        }
      });

      return sums;
    },
    // 表格表头样式
    handleHeaderCellStyle() {
      return "background-color:#ebf4ff;color:#333333;font-wight:400;";
    },
    // 浏览发票影像
    handlePreviewImage(row) {
      this.colInvoiceId = row.colInvoice.id;
      this.invoiceImageVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
