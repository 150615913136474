import { get, post, del, download } from "@/service/index.js";

// 分页查询订单
export const reqPagingSettlement = (param) => {
  return get(
    `/coo/settlement/trade/page-${param.cooRole == "DS" ? "buy" : "sal"}`,
    param
  ).then((res) => res);
};

/**
 * 批量导出
 * @param data
 * @returns {Promise<unknown>}
 */
export const settlementExport = (data) => {
  return download("/coo/settlement/trade/export", data).then((res) => res);
};

// 查询订单详情
export const reqSelectSettlement = (tradeId) => {
  return get(`/coo/settlement/trade/${tradeId}`).then((res) => res);
};

// 回退结算订单
export const reqBackSettlement = (data) => {
  return post(`/coo/settlement/trade/back`, data).then((res) => res);
};

// 作废结算订单
export const reqRevokeSettlement = (tradeId) => {
  return post(`/coo/settlement/trade/revoke/${tradeId}`).then((res) => res);
};

// 检查结算订单
export const reqCheckSettlement = (data) => {
  return post(`/coo/settlement/trade/check`, data).then((res) => res);
};

// 订单生成发票
export const reqGenerateInvoice = (data) => {
  return post(`/coo/settlement/trade/generate`, data).then((res) => res);
};

// 订单撤销开票
export const reqRescindInvoice = (data) => {
  return post(`/coo/settlement/trade/rescind`, data).then((res) => res);
};

// 回写结算订单
export const reqReturnSettlement = (data) => {
  return post(`/coo/settlement/trade/return`, data).then((res) => res);
};

// 删除结算订单
export const reqDeleteSettlement = (tradeId) => {
  return del(`/coo/settlement/trade/delete/${tradeId}`).then((res) => res);
};

// 新增结算订单
export const reqCreateSettlement = (data) => {
  return post(`/coo/settlement/trade/create`, data).then((res) => res);
};

// 更新结算订单
export const reqUpdateSettlement = (data) => {
  return post(`/coo/settlement/trade/update`, data).then((res) => res);
};

// 稽核结算订单
export const reqAuditSettlement = (tradeId) => {
  return post(`/coo/settlement/trade/audit/${tradeId}`).then((res) => res);
};

// 获取条码图片
export const reqSettlementBarCode = (tradeId) => {
  return get(`/coo/settlement/trade/bar-code/${tradeId}`).then((res) => res);
};

// 修改稽核项目
export const reqUpdateAuditOption = (data) => {
  return post(`/coo/settlement/trade/edit-audit-option`, data).then(
    (res) => res
  );
};

// 修改票据张数
export const reqUpdateTicketCount = (data) => {
  return post(`/coo/settlement/trade/edit-ticket-count`, data).then(
    (res) => res
  );
};
